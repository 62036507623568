<template>
  <div class="column pt-0">
    <div class="has-background-white p-4">
      <h5 class="mb-4 has-text-weight-medium">ЗАРЕГИСТРИРОВАТЬ СЕРВЕР</h5>
      <form @submit.prevent="SentAgentData">
        <p class="mb-3">Введите данные</p>

        <b-field label="Компания">
          <b-select
            placeholder="Выберите компанию"
            v-model="selected_company"
            required
          >
            <option
              v-for="option in company_list"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>

        <b-field label="Филиал">
          <b-select
            placeholder="Выберите филиал"
            v-model="selected_branch"
            required
          >
            <option
              v-for="option in branch_list"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>

        <b-field label="Завод">
          <b-select
            placeholder="Выберите завод"
            v-model="selected_factory"
            required
          >
            <option
              v-for="option in factory_list"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>

        <b-field label="Пользователи">
          <b-taginput
            v-model="user_ids"
            :data="users_list"
            autocomplete
            :allow-new="false"
            :open-on-focus="true"
            field="first_name"
            placeholder="Выберите пользователя"
            @typing="getFilteredTags"
            @input="handleValue"
          >
          </b-taginput>
        </b-field>

        <b-field class="control mt-4" label="Наименование контролируемого оборудования">
          <b-input
            type="text"
            v-model="device_name"
            placeholder="Наименование контролируемого оборудования"
            required
            :message="errors && errors['device_name']"
            :type="errors && errors['device_name'] && 'is-danger'"
          />
        </b-field>

        <b-field
          class="mt-4"
          label="IP адрес CMS (например: 5.251.157.236)"
          :message="errors && errors['ip']"
          :type="errors && errors['ip'] && 'is-danger'"
        >
          <b-input
            type="text"
            v-model.trim="ip"
            placeholder="IP адрес CMS"
            required
          />
        </b-field>

        <b-field
          class="mt-4"
          label="IP адрес IDM-Edge (например: 5.251.157.236)"
          :message="errors && errors['ip_local']"
          :type="errors && errors['ip_local'] && 'is-danger'"
        >
          <b-input
            type="text"
            v-model="ip_local"
            placeholder="IP адрес IDM-Edge"
            required
          />
        </b-field>

        <b-field
          class="mt-4"
          label="Время синхр. (например: 02)"
          :message="errors && errors['sync_hour']"
          :type="errors && errors['sync_hour'] && 'is-danger'"
        >
          <b-input
            type="text"
            v-model="sync_hour"
            placeholder="Часы"
            required
          />
        </b-field>

        <b-field
          class="mt-4"
          label="Время синхр. (например: 30)"
          :message="errors && errors['sync_minute']"
          :type="errors && errors['sync_minute'] && 'is-danger'"
        >
          <b-input
            type="text"
            v-model="sync_minute"
            placeholder="Минуты"
            required
          />
        </b-field>

        <b-field
          class="mt-4"
          label="Логин CMS"
        >
          <b-input
            type="text"
            v-model="login"
            placeholder="Логин CMS"
            required
          />
        </b-field>

        <b-field class="mt-4 mb-4" label="Пароль CMS">
          <b-input
            type="text"
            v-model="password"
            placeholder="Пароль CMS"
            required
          />
        </b-field>

        <b-field label="Параметры VIB-ов">
<!--          <b-select-->
<!--            placeholder="Выберите количество"-->
<!--            v-model="selectedVibsCount"-->
<!--            required-->
<!--          >-->
<!--            <option-->
<!--              v-for="option in module_count"-->
<!--              :value="option.id"-->
<!--              :key="option.id"-->
<!--            >-->
<!--              {{ option.name }}-->
<!--            </option>-->
<!--          </b-select>-->
          <b-input
            type="number"
            v-model="selectedVibsCount"
            placeholder="Количество каналов"
            required
          />
        </b-field>

        <b-field label="Параметры температуры">
<!--          <b-select-->
<!--            placeholder="Выберите количество"-->
<!--            v-model="selectedTempCount"-->
<!--            required-->
<!--          >-->
<!--            <option-->
<!--              v-for="option in temperature_count"-->
<!--              :value="option.id"-->
<!--              :key="option.id"-->
<!--            >-->
<!--              {{ option.name }}-->
<!--            </option>-->
<!--          </b-select>-->
          <b-input
            type="number"
            v-model="selectedTempCount"
            placeholder="Количество каналов"
            required
          />
        </b-field>

        <b-field
          class="mt-4"
          label="Сдвиг"
        >
          <!--      :message="errors && errors['shift']"-->
          <!--      :type="errors && errors['shift'] && 'is-danger'"-->
          <b-input
            type="number"
            v-model="shift"
            placeholder="shift"
            required
          />
        </b-field>
        <b-field
          class="mt-4"
          label="Коэффициент"
          :message="errors && errors['coefficient']"
          :type="errors && errors['coefficient'] && 'is-danger'"
        >
          <b-input
            type="number"
            step="0.01"
            min="0.0"
            v-model="coefficient"
            placeholder="coefficient"
            required
          />
        </b-field>

        <b-field label="Конфигурация">
          <section>
            <b-field><b-switch v-model="isTrendsActive" type="is-info">Тренды</b-switch></b-field>
            <b-field><b-switch v-model="isSpectersActive" type="is-info">Спектры</b-switch></b-field>
            <b-field><b-switch v-model="isTrendSpectersActive" type="is-info">Тренды полос спектров</b-switch></b-field>
            <b-field><b-switch v-model="isOilsActive" type="is-info">Тренды счетчиков частиц в масле</b-switch></b-field>
          </section>
        </b-field>

        <b-field
          class="mt-5 is-flex is-flex-direction-column is-align-items-center"
          label="Загрузка изображения"
        >
          <b-upload
            v-if="!result.image"
            drag-drop
            accept="image/*"
            @input="handleImageCrop"
          >
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <b-icon
                    icon="upload"
                    size="is-medium"
                  >
                  </b-icon>
                </p>
                <p>Нажмите или перетащите файл для загрузки</p>
              </div>
            </section>
          </b-upload>
          <div
            v-else
            class="has-text-centered"
          >
            <Preview
              :width="200"
              :height="200"
              :image="result.image"
              :coordinates="result.coordinates"
            />
            <b-button
              type="is-danger is-light mt-3"
              @click="deleteImage"
            >Удалить
            </b-button>
          </div>
        </b-field>

        <button class="button is-info mt-5">Зарегистрировать</button>
      </form>
      <b-modal
        v-model="cropperModal"
        has-modal-card
        :can-cancel="false"
        @close="handleCropperModalClose"
      >
        <div class="card">
          <Cropper
            v-if="file"
            ref="cropper"
            :src="file"
            class="cropper"
            :stencil-props="{
              aspectRatio: 1,
            }"
          />
          <div
            class="px-4 py-3 is-flex is-justify-content-flex-end is-align-items-center"
            style="gap: 10px"
          >
            <b-button
              type="is-light"
              @click="handleCropperModalClose"
            >Отменить
            </b-button>
            <b-button
              type="is-success"
              @click="saveCroppedImage"
            >Сохранить
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>


<script>
import { Cropper, Preview } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  components: { Cropper, Preview },
  data() {
    return {
      file: undefined,
      result: {
        coordinates: null,
        image: null,
        canvas: undefined,
      },
      filteredTags: this.users_list,
      cropperModal: false,
      selectedUsers: [],
      isSelectOnly: false,
      module_count: [
        {
          id: 4,
          name: "Четыре канала",
        },
        {
          id: 8,
          name: "Восемь каналов",
        },
        {
          id: 12,
          name: "Двенадцать каналов",
        },
      ],
      temperature_count: [
        {
          id: 0,
          name: "Отсутствует",
        },
        {
          id: 4,
          name: "Четыре канала",
        },
        {
          id: 8,
          name: "Восемь каналов",
        },
      ],
      selectedVibsCount: null,
      selectedTempCount: null,
      company_list: null,
      selected_company: null,
      branch_list: null,
      selected_branch: null,
      factory_list: null,
      selected_factory: null,
      users_list: null,
      ip: null,
      factory_id: null,
      device_name: null,
      ip_local: null,
      name: null,
      login: null,
      password: null,
      sync_hour: null,
      sync_minute: null,
      shift: null,
      coefficient: 1.0,
      user_ids: [],
      errors: [],
      isTrendsActive: false,
      isSpectersActive: false,
      isTrendSpectersActive: false,
      isOilsActive: false,
    };
  },
  watch: {
    selected_company() {
      this.getBranch(this.selected_company);
    },
    selected_branch() {
      this.getFactory(this.selected_branch);
    },
    selected_factory() {
      this.getUsers(this.selected_branch);
    },
  },
  created() {
    this.getCompany();
  },
  methods: {
    handleImageCrop(image) {
      if (image) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.file = e.target.result;
          console.log(this.file);
          this.cropperModal = true;
          this.$nextTick(() => {
            this.$refs.cropper.refresh();
          });
        };
        reader.readAsDataURL(image);
      }
    },
    async saveCroppedImage() {
      this.result = this.$refs.cropper.getResult();
      this.result.data = this.result.canvas.toDataURL("image/jpeg");
      this.result.canvas.toBlob((blob) => {
        this.result.size = blob.size;
      }, "image/jpeg");
      this.handleCropperModalClose();
    },
    handleValue(value) {
      this.selectedUsers = value.map(user => user.id);
    },
    getFilteredTags(text) {
      this.filteredTags = this.users_list.filter((option) => {
        return option.first_name
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0;
      });
    },
    async getCompany() {
      await this.$axios.get(`admin/companies`).then(response => {
        this.company_list = response.data.data;
      });
    },
    async getBranch(e) {
      await this.$axios.get(`admin/companies/${e}/branches/`).then(response => {
        this.branch_list = response.data.data;
      });
    },
    async getFactory(e) {
      await this.$axios.get(`admin/branches/${e}/factories`).then(response => {
        this.factory_list = response.data.data;
      });
    },
    async getUsers(e) {
      await this.$axios.get(`admin/branches/${e}/users`).then(response => {
        this.users_list = response.data.data;
      });
    },
    async SentAgentData() {
      let data = {
        "ip": this.ip,
        "factory_id": this.selected_factory,
        "ip_local": this.ip_local,
        "device_name": this.device_name,
        "login": this.login,
        "password": this.password,
        "sync_hour": this.sync_hour,
        "sync_minute": this.sync_minute,
        "user_ids": this.selectedUsers,
        "module_count": this.selectedVibsCount,
        "temperature_count": this.selectedTempCount,
        "shift": this.shift,
        "coefficient": this.coefficient,
        "config": {
          trends: this.isTrendsActive,
          specters: this.isSpectersActive,
          trend_specters: this.isTrendSpectersActive,
          oils: this.isOilsActive
        },
        "image": {
          size: this.result.size,
          data: this.result.data,
        },
      };

      // Object.entries(data).forEach(([key, value]) => {
      //   form.append(key, value)
      // })

      await this.$axios.post(`admin/agents`, data)
        .then(() => {
          this.$router.push("/cms-list");
        })
        .catch((err) => {
          this.errors = err?.error?.response?.data?.errors || []
          console.log('ERRORS', this.errors);
        })
    },

    handleCropperModalClose() {
      this.file = undefined;
      this.cropperModal = false;
    },

    deleteImage() {
      this.result = {
        coordinates: null,
        image: null,
        canvas: undefined,
      };
    },
  },
};
</script>

<style lang="scss">
.cropper__container {
  background-color: #DDD;
  max-height: 85vh;
  padding: 24px;
}

.cropper {
  max-height: 500px;
}

.icon svg {
  height: 1.5em;
}
</style>
